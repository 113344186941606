import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

const Signin = Loadable(lazy(() => import('views/auth')));
const Registration = Loadable(lazy(() => import('views/auth/registration')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/signin',
    element: <Signin />
};

const RegistrationRoute = {
    path: 'registration',
    element: <Registration />
};
export { RegistrationRoute };
export default AuthenticationRoutes;
