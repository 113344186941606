import EtDate from './ethiopian-date';
const etTime = {
    0: 12,
    1: 7,
    2: 8,
    3: 9,
    4: 10,
    5: 11,
    6: 12,
    7: 1,
    8: 2,
    9: 3,
    10: 4,
    11: 5,
    12: 6,
    13: 7,
    14: 8,
    15: 9,
    16: 10,
    17: 11,
    18: 12,
    19: 1,
    20: 2,
    21: 3,
    22: 4,
    23: 5
};

export const formatNumber = (passednum) => {
    const number = Math.abs(passednum);
    if (passednum < 0) {
        if (number >= 1000000) {
            return -(number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return -(number / 1000).toFixed(1) + 'K';
        } else {
            return -number.toString();
        }
    } else {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number.toString();
        }
    }
};

export function isDateEqualToToday(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();

    // Extract year, month, and day from input date
    const inputYear = inputDate.getFullYear();
    const inputMonth = inputDate.getMonth();
    const inputDay = inputDate.getDate();

    // Extract year, month, and day from today's date
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    // Compare the date components
    if (inputYear === currentYear && inputMonth === currentMonth && inputDay === currentDay) {
        return true;
    }

    return false;
}
// get number of days in month
//use current month and year as initial inputs
export const getDaysInMonth = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Adding 1 because months are zero-based

    return new Date(year, month, 0).getDate();
};

export const calculatePercentage = (numerator, denominator) => {
    if (denominator === 0) {
        return 0;
    }
    const percentage = (numerator / denominator) * 100;
    return percentage.toFixed(2); // Limiting the result to 2 decimal places
};

export const DateFormatter = (thedate) => {
    const isAmharic = localStorage.getItem('language');
    if (isAmharic === 'am') {
        const ethioDate = EtDate(thedate);
        return ethioDate;
    } else {
        var year = thedate.slice(0, 4);
        var month = thedate.slice(5, 7);
        var day = thedate.slice(8, 10);
        const date = day + '-' + month + '-' + year;
        return date;
    }
};

export const Achievement = (revenue, target) => {
    let status;
    if (revenue >= target) {
        status = 'achieved';
        return status;
    } else {
        status = 'in-progress';
        return status;
    }
};

export const formatDate = (createdAt) => {
    const date = new Date(createdAt);

    // Get the month name
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const month = monthNames[date.getMonth()];

    // Get the day and pad it with leading zeros if necessary
    const day = String(date.getDate()).padStart(2, '0');

    // Get the year
    const year = date.getFullYear();

    // Format the date as DDMMYYYY
    const formattedDate = `${day}-${month.slice(0, 3)}-${year}`;

    return {
        monthName: month.slice(0, 3),
        formattedDate: formattedDate
    };
};
