// material-ui
import logo from 'assets/images/logo.png';
import PropTypes from 'prop-types';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         **/
        <img src={logo} alt="sheket" style={{ width: '60px', height: '40px', resize: 'cover' }} />
    );
};

//a customizable logo as per specific design need
const Logotwo = ({ w, h, sx }) => {
    return <img src={logo} alt="sheket" style={{ width: w, height: h, resize: 'cover', ...sx }} />;
};

Logotwo.propTypes = {
    w: PropTypes.number,
    h: PropTypes.number,
    sx: PropTypes.object
};

export { Logotwo };
export default Logo;
