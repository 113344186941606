// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase } from '@mui/material';
// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';

// assets
import Language from 'ui-component/language';
import CartItems from './Cart';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}

            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '30px', overflow: 'hidden' }}>
                    <LogoSection />
                </ButtonBase>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* <HeaderNavs /> */}
            <CartItems />
            <Language />
        </>
    );
};

export default Header;
