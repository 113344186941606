import { createSlice } from '@reduxjs/toolkit';

const calculateGrandTotal = (items) => {
    return items.reduce((total, item) => parseInt(total) + parseInt(item.subtotal), 0);
};

const initialState = {
    items: [],
    grandTotal: 0
};
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const { name, product } = action.payload;
            const existingItem = state.items.find((item) => item.id === product.id);

            if (existingItem) {
                existingItem.quantity += 1;
                existingItem.subtotal = existingItem.quantity * existingItem.unitPrice;
                state.grandTotal = calculateGrandTotal(state.items);
            } else {
                state.items.push({
                    id: product.id,
                    image: product.picture,
                    name: name,
                    code: product.code,
                    brand: product.brand,
                    sku: product.sku,
                    unit: product.unit,
                    unitPrice: product.price,
                    quantity: 1,
                    subtotal: product.price
                });
                state.grandTotal = calculateGrandTotal(state.items);
            }
        },
        removeItem: (state, action) => {
            const { id } = action.payload;
            const existingItem = state.items.find((item) => item.id === id);

            if (existingItem) {
                state.items = state.items.filter((item) => item.id !== id);
                state.grandTotal = calculateGrandTotal(state.items);
            }
        },
        incrementQuantity: (state, action) => {
            const { id } = action.payload;
            const index = state.items.findIndex((item) => item.id === id);
            state.items[index].quantity++;
            state.items[index].subtotal = state.items[index].unitPrice * state.items[index].quantity;
            state.grandTotal = calculateGrandTotal(state.items);
        },

        decrementQuantity: (state, action) => {
            const { id } = action.payload;
            const index = state.items.findIndex((item) => item.id === id);

            if (state.items[index].quantity > 1) {
                state.items[index].quantity--;
                state.items[index].subtotal = state.items[index].unitPrice * state.items[index].quantity;
            } else if (state.items[index].quantity === 1) {
                state.items = state.items.filter((item) => item.id !== id);
            }

            state.grandTotal = calculateGrandTotal(state.items);
        },
        resetCart: (state) => {
            state.items = [];
            state.grandTotal = 0;
        },
        setGrandTotal: (state, action) => {
            state.grandTotal = action.payload;
        }
    }
});

export const { addItem, removeItem, incrementQuantity, decrementQuantity, resetCart, setGrandTotal } = cartSlice.actions;

export default cartSlice.reducer;
