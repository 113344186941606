import * as React from 'react';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router';
import { IconCubeSend, IconShoppingBag, IconTimeline, IconUser } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigation from '@mui/material/BottomNavigation';

export default function BottomTabs() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(sessionStorage.getItem('bottomtab') || 'items');

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem('bottomtab', newValue);
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2, overflow: 'hidden' }} elevation={3}>
            <BottomNavigation value={value} onChange={handleChange} showLabels>
                <BottomNavigationAction label={t('Items')} value="items" icon={<IconCubeSend size={30} />} onClick={() => navigate('/')} />
                <BottomNavigationAction
                    label={t('Gebeya')}
                    value="market"
                    icon={<IconTimeline size={26} />}
                    onClick={() => navigate('/market')}
                />
                <BottomNavigationAction
                    label={t('Orders')}
                    value="orders"
                    icon={<IconShoppingBag size={24} />}
                    onClick={() => navigate('/orders')}
                />
                <BottomNavigationAction
                    label={t('Profile')}
                    value="profile"
                    icon={<IconUser size={24} />}
                    onClick={() => navigate('/profile')}
                />
            </BottomNavigation>
        </Paper>
    );
}
