import { useTranslation } from 'react-i18next';

const Language = () => {
    let active_lang = localStorage.getItem('language') || 'en';
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
        const language = event.target.value;

        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    return (
        <select value={active_lang} style={{ border: 'none', marginRight: 2, padding: 6, borderRadius: 4 }} onChange={changeLanguage}>
            <option value="am">🇪🇹 {t('Am')}</option>
            <option value="en">🇺🇸 {t('En')}</option>
        </select>
    );
};

export default Language;
