import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PriceChangeRecords from 'views/price-change/details';

//price change records
const PriceChange = Loadable(lazy(() => import('views/price-change')));
const ItemList = Loadable(lazy(() => import('views/price-change/ItemLists')));
const Market = Loadable(lazy(() => import('views/market')));
const Profile = Loadable(lazy(() => import('views/profile')));
const EditProfile = Loadable(lazy(() => import('views/profile/edit')));
const Checkout = Loadable(lazy(() => import('views/checkout')));
const Orders = Loadable(lazy(() => import('views/orders')));
const OrderDetail = Loadable(lazy(() => import('views/orders/details')));
const Cart = Loadable(lazy(() => import('views/cart')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <PriceChange />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <PriceChange />
                }
            ]
        },

        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'pricing-changes',
            element: <PriceChange />
        },
        {
            path: 'itemlisting',
            element: <ItemList />
        },
        {
            path: 'change-records',
            element: <PriceChangeRecords />
        },
        {
            path: 'market',
            element: <Market />
        },

        {
            path: 'profile',
            element: <Profile />
        },
        { path: 'profile/edit', element: <EditProfile /> },
        {
            path: 'checkout',
            element: <Checkout />
        },
        {
            path: 'cart',
            element: <Cart />
        },
        {
            path: 'orders',
            element: <Orders />
        },
        {
            path: 'order/detail',
            element: <OrderDetail />
        }
    ]
};

export default MainRoutes;
